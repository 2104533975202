@mixin reset-text-indents() {
  font-size: 0;
  line-height: 0;
}

@mixin reset-btn() {
  box-sizing: border-box;
  display: block;
  margin: 0;
  border: none;
  padding: 0;

  // white-space: nowrap;
  vertical-align: middle;
  user-select: none;
  text-decoration: none;
  text-transform: none;
  border-radius: 0;
  background-color: transparent;
  background-image: none;
  cursor: pointer;
  outline: 0;
  font-family: inherit;
  appearance: none;

  &:focus {
    outline: 0;
  }
}

@mixin reset-scrollbar() {
  -ms-overflow-style: none;
  scrollbar-width: none;
  scrollbar-color: transparent transparent;
  overflow: -moz-scrollbars-none;

  &::-webkit-scrollbar {
    display: none;
  }
}
