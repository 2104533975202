@import 'abstracts/variables', 'abstracts/functions', 'abstracts/mixins';
.next-image {
  $block-name: &;

  @include reset-text-indents;

  display: flex;

  &__inner {
    max-width: 100%;
  }

  img {
    &#{$block-name} {
      &__img {
        @include skeleton;

        width: 100%;
        height: 100%;
        object-fit: cover;

        &--is-loaded {
          background: none;
        }
      }
    }
  }

  &--disable-loading-state {
    img {
      &#{$block-name} {
        &__img {
          background: none !important;
        }
      }
    }
  }
}
